import "sanitize.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto-mono/100.css";
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/500.css";
import "@fontsource/roboto-mono/700.css";

import * as am4core from "@amcharts/amcharts4/core";
import AmchartsThemeAnimated from "@amcharts/amcharts4/themes/animated";
import AmchartsThemeDark from "@amcharts/amcharts4/themes/dark";
import { globalStyles, useTheme } from "capitalroadkit";
import React, { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import injectSheet from "react-jss";

import { host } from "./api/utilities";
import { useAuthorization } from "capitalroadkit";
import "./managers/18next/i18n";
import { useLocalStorage } from "capitalroadkit";
import Loading from "./Loading";
import { useSessionStorage } from "capitalroadkit";

const LandingRouter = lazy(() => import("./routers/LandingRouter"));
const SecureRouter = lazy(() => import("./routers/SecureRouter"));

const App = () => {
  const [authorization] = useAuthorization();
  const [theme] = useTheme();

  const [] = useSessionStorage("tenant", theme.tenant);

  useEffect(() => {
    am4core.addLicense("CH200583143");
  }, []);

  useEffect(() => {
    if (theme.mode === "dark") am4core.useTheme(AmchartsThemeDark);
    else am4core.unuseTheme(AmchartsThemeDark);
  }, [theme]);

  const [isAnimationsEnabled] = useLocalStorage("animatedCharts", true);

  useEffect(() => {
    if (isAnimationsEnabled) am4core.useTheme(AmchartsThemeAnimated);
    else am4core.unuseTheme(AmchartsThemeAnimated);
  }, [isAnimationsEnabled]);

  return (
    <div
      id="app"
      style={{
        backgroundColor: theme.palette.background.main,
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <link href={host()} rel="preconnect" />
      </Helmet>
      <Router>
        <Suspense fallback={<Loading />}>{authorization ? <SecureRouter /> : <LandingRouter />}</Suspense>
      </Router>
    </div>
  );
};

export default injectSheet(globalStyles)(App);
