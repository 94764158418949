import i18n from "i18next";
import React from "react";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init(
  {
    lng: "en",
    debug: true,
    resources: {
      en: {
        translation: {
          key: "hello world",
        },
      },
    },
  },
  (err, t) => {
    // initialized and ready to go!
  }
);

export default i18n;
