import EnvironmentEnums from "../types/EnvironmentEnum";

const useEnvironment = () => {
  if (window.location.href.includes("localhost")) {
    return EnvironmentEnums.LOCALHOST;
  }

  if (window.location.href.includes("nonprod")) {
    if (window.location.href.includes("dev2")) {
      return EnvironmentEnums.DEV2;
    }
    if (window.location.href.includes("dev")) {
      return EnvironmentEnums.DEV;
    }

    if (window.location.href.includes("sit2")) {
      return EnvironmentEnums.SIT2;
    }

    if (window.location.href.includes("sit")) {
      return EnvironmentEnums.SIT;
    }

    if (window.location.href.includes("uat")) {
      return EnvironmentEnums.UAT;
    }
  }

  return EnvironmentEnums.PROD;
};

export default useEnvironment;
