import { AuthorizationProvider, useTheme } from "capitalroadkit";
import React from "react";

import useEnvironment from "./hooks/useEnvironment";
import CognitoConfiguration from "./types/cognito/CognitoConfiguration";

const ProviderWrapper = ({ children }) => {
  const environment = useEnvironment();
  const [theme] = useTheme();

  return (
    <AuthorizationProvider
      cognitoDomainUrl={CognitoConfiguration[environment]["DOMAIN"]}
      clientPoolId={CognitoConfiguration[environment]["TENANT"][theme.tenant]["CLIENT_ID"]}
      logoutRoute={"/logout"}
    >
      {children}
    </AuthorizationProvider>
  );
};

export default ProviderWrapper;
