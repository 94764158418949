import { v4 as uuidv4 } from "uuid";

export const host = () => {
  const { hostname } = window.location;
  const hostArray = hostname.split(".");
  if (hostArray.length === 1) return "http://localhost:8080/custodian-se";
  hostArray[0] += "-api";
  return `https://${hostArray.join(".")}`;
};

export const getHeaders = (authorization, tenant) => {
  const headers = {
    "X-Correlation-ID": uuidv4(),
    "content-type": "application/json",
  };

  if (authorization) headers.Authorization = authorization["access_token"];
  if (tenant) headers["x-cr-tenant"] = tenant;

  return headers;
};
