const CognitoConfiguration = Object.freeze({
  LOCALHOST: {
    DOMAIN: "https://capitalroad-localhost.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "1md81o8ppea3bmunh6klki2php",
      },
      CAPITALROAD: {
        CLIENT_ID: "1md81o8ppea3bmunh6klki2php",
      },
    },
  },
  DEV2: {
    DOMAIN: "https://dev2-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "5c6jq0qikn5v61vk76j1det45o",
      },
      CAPITALROAD: {
        CLIENT_ID: "5c6jq0qikn5v61vk76j1det45o",
      },
    },
  },
  DEV: {
    DOMAIN: "https://dev-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "4hrnema1qi2orcm92fjai7k0fa",
      },
      CAPITALROAD: {
        CLIENT_ID: "4hrnema1qi2orcm92fjai7k0fa",
      },
    },
  },
  SIT2: {
    DOMAIN: "https://sit2-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "7ib29l7dstinjq5fnre4q60727",
      },
      CAPITALROAD: {
        CLIENT_ID: "7ib29l7dstinjq5fnre4q60727",
      },
    },
  },
  SIT: {
    DOMAIN: "https://sit-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "6nstllscqm1lf7rugfea0aoou3",
      },
      CAPITALROAD: {
        CLIENT_ID: "6nstllscqm1lf7rugfea0aoou3",
      },
    },
  },
  UAT: {
    DOMAIN: "https://uat-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "7tvlggs4eva7ajgv1b7975lg2i",
      },
      CAPITALROAD: {
        CLIENT_ID: "7tvlggs4eva7ajgv1b7975lg2i",
      },
    },
  },
  PROD: {
    DOMAIN: "https://prod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    TENANT: {
      AREAWEALTH: {
        CLIENT_ID: "410h01oqlt8v5vo30bdh788hb5",
      },
      CAPITALROAD: {
        CLIENT_ID: "410h01oqlt8v5vo30bdh788hb5",
      },
    },
  },
});

export default CognitoConfiguration;
